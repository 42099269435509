<script lang="ts">
    import {testimonials} from '$lib/components/testimonial/testimonials';
    import TestimonialText from '$lib/components/_home/testimonial-text.svelte'
    import {onMount} from 'svelte';
    import type Masonry from 'masonry-layout';

    let masonry: Masonry

    onMount(async () => {
        const result = await import ('masonry-layout')
        const Masonry = result.default
        masonry = new Masonry('#testimonials', {
            itemSelector: '.testimonial',
            columnWidth: '.grid-sizer',
            gutter: '.gutter-sizer',
            // percentPosition: true, //Note flickering width when opening/closing
        });
    })

    function handleChange() {
        if (masonry) {
            // @ts-ignore
            masonry.layout()
        }
    }
</script>

<section>
    <h2>
        What do others say?
    </h2>
    <div id="testimonials">
        <div class="grid-sizer"></div>
        <div class="gutter-sizer"></div>
        {#each testimonials as {name, date, text, imgSrc, src, long}, index}
            <div class="testimonial grid-sizer">
                <div class="header">
                    <div class="img-wrapper">
                        <img src="/img/testimonial/40x40/{imgSrc}.png" alt="profile image {name}" width="40"
                             height="40">
                    </div>
                    <div>
                        {name}
                    </div>
                    {#if src === 'reddit'}
                        <img src="/img/testimonial/_reddit_logo.png" alt="reddit logo" width="18" height="18"
                             style="margin-left: auto;"
                        >
                    {/if}
                </div>
                <TestimonialText
                        text={text}
                        id={index}
                        long={long}
                        onChange={handleChange}
                />
                <time datetime={date}>{new Date(date).toLocaleString(undefined, {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                })}</time>
            </div>
        {/each}
        <div class="testimonial grid-sizer call-to-action">
            <a href="/testimonial"
               class="link-button"
               style="background: var(--color-Preposition);"
            >
                » Add your review
            </a>
        </div>
    </div>
</section>

<style>
    section {
        margin-bottom: 4rem;
    }

    #testimonials {
        container-name: testimonials;
        container-type: inline-size;
    }

    .grid-sizer {
        width: 32%;
    }

    .gutter-sizer {
        width: 2%;
    }

    @media (max-width: 1499px) {
        .grid-sizer {
            width: 49%;
        }
    }

    @media (max-width: 1220px) {
        .grid-sizer {
            width: 100%;
        }

        .gutter-sizer {
            width: 0;
        }
    }

    /* overwrite media queries with container query when available */

    @container (min-width: 800px) {
        .grid-sizer {
            width: 32%;
        }

        .gutter-sizer {
            width: 2%;
        }
    }

    @container (max-width: 799px) {
        .grid-sizer {
            width: 49%;
        }

        .gutter-sizer {
            width: 2%;
        }
    }

    @container (max-width: 500px) {
        .grid-sizer {
            width: 100%;
        }

        .gutter-sizer {
            width: 0;
        }
    }

    .testimonial {
        /* width set via .grid-sizer class */
        display: grid;
        align-content: flex-start;
        gap: .6rem;
        padding: 1rem;
        font-size: .94rem;
        border: 1px solid #e6e6e6;
        border-radius: 1rem;
        margin-bottom: 1rem;
    }

    .header {
        display: flex;
        gap: 1rem;
        align-items: center;
    }

    .img-wrapper {
        background-color: #ededed;
        border-radius: 50%;
        overflow: hidden;
    }

    img {
        display: block;
    }

    time {
        font-size: .85rem;
        color: var(--color-darker);
    }

    /*.call-to-action {*/
    /*    display: flex;*/
    /*    justify-content: center;*/
    /*    border: revert;*/
    /*}*/

    /*a {*/
    /*    background-color: var(--color-Preposition);*/
    /*    !*display: block;*!*/
    /*}*/
</style>